import React, { useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import TextArea from "../../ui/common/TextArea";
import Select from "../../ui/select/Select";
import ToggleSwitch from "../../ui/toggleSwitch/ToggleSwitch";
import cronstrue from "cronstrue";
import cronParser from "cron-parser";
import { useSelector } from "react-redux";
import "./EditNotifiersModal.scss";
import { fetchBrands } from "../../../store/slices/brands";
import { updateNotifier } from "../../../store/slices/notifiers";
import { useAppDispatch } from "../../../store";

const prepareParams = (paramsData) => {
  let params = { ...paramsData };
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      if (Array.isArray(params[key])) {
        if (params[key].length === 1 && params[key][0].substring(0, params[key][0].length - 1) !== ",") {
          params[key][0] = params[key][0] + ",";
        } else {
          params[key] = params[key].join(",\n");
        }
      }
      if (typeof params[key] === "object") {
        params[key] = JSON.stringify(params[key], null, 2);
      }
    }
  }
  return params;
};

const EditNotifiersModal = ({ data, closeModal }) => {
  const dispatch = useAppDispatch();
  const brands = useSelector((state: any) => state.brands.data);
  const [notifierId, setNotifierId] = useState(data.id);
  const [name, setName] = useState(data.name);
  const [brandId, setBrandId] = useState(data.brandId || "");
  const [type, setType] = useState(data.type || " ");
  const [filteringTags, setFilteringTags] = useState(data.filteringTags);
  const [cronTime, setCronTime] = useState(data.cronTime);
  const [notifierActive, setNotifierActive] = useState(data.active);
  const [params, setParams] = useState(prepareParams(data.params));
  const notifierTypes = useSelector((state: any) => state.notifiers.notifierTypes);

  const updatingNotifier = useSelector((state: any) => state.notifiers.updatingNotifier);
  const updatingNotifierError = useSelector((state: any) => state.notifiers.updatingNotifierError);
  const [hasAttemptedUpdate, setHasAttemptedUpdate] = useState(false);

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  const humanReadableCron = useMemo(() => {
    try {
      return cronstrue.toString(cronTime);
    } catch {
      return "";
    }
  }, [cronTime]);

  const cronNextIteration = useMemo(() => {
    try {
      return `Next iteration: ${cronParser.parseExpression(cronTime).next().toString()}`;
    } catch {
      return "";
    }
  }, [cronTime]);
  
  const brandOptions = useMemo(() => {
    return brands.map((brand) => {
      return brand.id;
    });
  }, [brands]);

  const prepareParamsForSave = (paramsData) => {
    let params = { ...paramsData };
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const paramType = notifierTypes[type].find((param) => param.field === key).type;
        if (params[key] && paramType === "array" && typeof params[key] === "string" && params[key].includes(",")) {
          params[key] = params[key].split(",").map((item) => item.trim()).filter(item => item);
        }
        
        if (key === "messageAlternatives") {
          params[key] = JSON.parse(params[key]);
        }
      }
    }
    return params;
  };


  const handleParamValueChange = (value: any, field: string) => {
    setParams({
      ...params,
      [field]: value,
    });
  };

  const handleSaveNotifier = useCallback(async () => {
    const payload = {
      active: notifierActive,
      brandId,
      cronTime,
      filteringTags: filteringTags,
      name,
      params: prepareParamsForSave(params),
      type,
    };

    await dispatch(updateNotifier(notifierId, payload));
    setHasAttemptedUpdate(true);
    closeModal();

  }, [notifierActive, brandId, cronTime, humanReadableCron, cronNextIteration, filteringTags, name, params, type, notifierId, dispatch, closeModal]);



  useEffect(() => {
    if (hasAttemptedUpdate && !updatingNotifier) {
      if (updatingNotifierError === null) {
        console.log("Notifier updated successfully, close modal");
        closeModal();
      }
    }
  }, [hasAttemptedUpdate, updatingNotifier, updatingNotifierError]);


  const paramsForm = useMemo(() => {
    if (notifierTypes[type] === undefined) {
      return "";
    }
    return (
      <div className="params-container">
        {notifierTypes[type].map((param, key) => {
          return (
            <div className="param-container" key={key}>
              <p>{param.field}</p>
              {param.type === "boolean" ? (
                <ToggleSwitch
                  checked={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e, param.field);
                  }}
                />
              ) : param.type === "array" || param.type === "object" ? (
                <TextArea
                  value={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                />
              ) : (
                <Input
                  value={params[param.field]}
                  type={param.type}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                  fullWidth
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }, [notifierTypes, type, params]);

  const shouldShowParams = notifierTypes[type]?.length > 0;

  return (
    <div className="notifiers-modal-wrapper">
      <h1 className="notifiers-modal-title">Edit notifier</h1>
      <div className="notifiers-modal-container">
        <div className={["column", `${shouldShowParams ? "narrow" : "wide"}`].join(" ")}>
          <Input label="Raw Id" value={notifierId} disabled separateLines fullWidth />
          <Input
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            separateLines
            fullWidth
          />
          <div className="drop-select">
            <label htmlFor="type">Type</label>
            <Select
              options={Object.keys(notifierTypes).sort()}
              disabled={true}
              selectedOption={type}
              setSelectedOption={(e) => {
                setType(e);
              }}
              styling={{
                selectOptionsContainerClassName: "types-options-dropdown",
              }}
              fullWidth
            />
          </div>

          <div className="drop-select">
            <label htmlFor="type">Brand Id</label>
            <Select
              options={brandOptions}
              disabled={true}
              selectedOption={brandId}
              setSelectedOption={(e) => {
                setBrandId(e);
              }}
              styling={{
                selectOptionsContainerClassName: "brand-options-dropdown",
              }}
              fullWidth
            />
          </div>
        </div>
        <div className={["column", `${shouldShowParams ? "narrow" : "wide"}`].join(" ")}>
          <p>
            Filtering Tags <small>for a single item, please add a ',' at the end of the line</small>
          </p>

          <TextArea
            value={filteringTags ? filteringTags.join(",\n") : ""}
            onChange={(e) => {
              setFilteringTags(e.target.value);
            }}
            className="filtering-tags-textarea"
          />

          <p>CronTime (based on Europe/Amsterdam timezone)</p>
          <p>{humanReadableCron}</p>
          <p>{cronNextIteration}</p>

          <Input
            label=""
            value={cronTime}
            onChange={(e) => {
              setCronTime(e.target.value);
            }}
            fullWidth
          />

          <div className="notifier-active-container">

            <ToggleSwitch
              checked={notifierActive}
              onChange={(e) => {
                setNotifierActive(e === true);
              }}
            />{" "}
            <span className="notifier-active-label">Active</span>

          </div>
        </div>
        {shouldShowParams && (
          <div className="column narrow">
            <div className="params-wrapper">
              <p>Params</p>
              {paramsForm}
            </div>
          </div>
        )}
      </div>
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSaveNotifier();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default EditNotifiersModal;
