import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  loading: boolean;
  token: string;
  error: object | null;
};

const INITIAL_STATE = { loading: false, token: null, error: null };

const auth = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    loginStart: () => ({
      loading: true,
      token: null,
      error: null,
    }),
    loginSuccess: (_, action) => ({
      loading: false,
      token: action.payload,
      error: null,
    }),
    loginFail: (_, action) => ({
      loading: false,
      token: null,
      error: action.payload,
    }),
    clearError: () => ({
      loading: false,
      token: null,
      error: null,
    }),
    logout: () => INITIAL_STATE,
  },
});

const { loginStart, loginSuccess, loginFail, clearError, logout } = auth.actions;

const removeCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const asyncLogin = (user) => async (dispatch: Function) => {
  try {
    dispatch(loginStart());
    const api = new Api();
    const response = await api.login(user);
    if (response.login_status) {
      dispatch(loginSuccess(response.token));
    } else {
      dispatch(loginFail("Invalid password or email address"));
    }
  } catch (err: any) {
    dispatch(loginFail(err.message));
  }
};

export const loginViaBrandSwith = () => (dispatch: Function) => {
  dispatch(loginSuccess("true"));
};

export const loginViaAuthToken = (token: string) => (dispatch: Function) => {
  dispatch(loginSuccess(token));
};

export const asyncLogout = (navigate?: Function) => async (dispatch) => {
  const api = new Api();
  const response = await api.logout();
  dispatch(logout());
  removeCookie("AUTH_COOKIE");

  if (navigate) {
    navigate("/login");
  } else {
    window.location.reload();
  }
};

export const clearErrorState = () => async (dispatch: Function) => {
  dispatch(clearError());
};

export default auth.reducer;
