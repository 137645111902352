import React, { useEffect, useMemo, useState } from "react";
import ReportRowWithNumbers from "./ReportRowWithNumbers";
import ReportRowWithList from "./ReportRowWithList";
import ReportRowABTests from "./ReportRowABTests";
import WeekOfTheYear from "../utils/WeekOfTheYear";
import {
  fetchNotificationStatistics,
  fetchRoiOwnPlatformStatistics,
  fetchRoiSocialStatistics,
  fetchRoiConversionsStatistics,
  fetchHeaderTestsStatistics,
} from "../../store/slices/statistics";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import useDateTimezoneAdjust from "../../customHooks/useDateTimezoneAdjust";
import "./SmartifyReport.scss";

type SmartifyReportProps = {
  type: "weekly" | "daily";
};

const SmartifyReport = ({ type }: SmartifyReportProps) => {
  const dispatch = useAppDispatch();
  const dateTimezoneAdjust = useDateTimezoneAdjust();

  const {
    notificationData,
    roiOwnPlatformData,
    roiSocialData,
    roiConversionsData,
    headerTestsData,
    notificationLoading,
    roiOwnPlatformLoading,
    roiSocialLoading,
    roiConversionsLoading,
    headerTestsLoading,
  } = useSelector((state: any) => state.statistics);
  const currentBrand = useSelector((state: any) => state.currentBrand.data);
  const { notificationsNotDone, missedOpportunities, firstPeriod, growth } = notificationData;

  const [pageLoaded, setPageLoaded] = useState("");

  useEffect(() => {
    const allLoadingDone =
      !notificationLoading &&
      !roiOwnPlatformLoading &&
      !roiSocialLoading &&
      !roiConversionsLoading &&
      !headerTestsLoading;
    if (allLoadingDone) {
      setPageLoaded("pageLoaded");
    }
  }, [notificationLoading, roiOwnPlatformLoading, roiSocialLoading, roiConversionsLoading, headerTestsLoading]);

  const param = type === "weekly" ? "week" : "day";

  useEffect(() => {
    dispatch(fetchNotificationStatistics(param));
    dispatch(fetchRoiOwnPlatformStatistics(param));
    dispatch(fetchRoiSocialStatistics(param));
    dispatch(fetchRoiConversionsStatistics(param));
    dispatch(fetchHeaderTestsStatistics(param));
  }, [dispatch, param]);

  const yesterdayDate = useMemo(() => {
    let date = new Date();
    let yesterday = date.setDate(date.getDate() - 1);
    let timestamp = yesterday.valueOf();

    let dateAdjusted = dateTimezoneAdjust(timestamp);
    return `${("0" + dateAdjusted.getDate()).slice(-2)} ${new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      dateAdjusted,
    )} ${dateAdjusted.getFullYear()}`;
  }, [dateTimezoneAdjust]);

  const preparedNotificationNotDoneItems = useMemo(() => {
    if (!notificationsNotDone) return [];
    return notificationsNotDone.map((item) => {
      return {
        ...item,
        title: item.notification,
        number: item.percent,
      };
    });
  }, [notificationsNotDone]);

  const preparedMissedOpportunitiesItems = useMemo(() => {
    if (!missedOpportunities) return [];
    return missedOpportunities.map((item) => {
      return {
        ...item,
        title: item.title,
        number: item.amount,
      };
    });
  }, [missedOpportunities]);

  const preparedTopAuthors = useMemo(() => {
    if (!headerTestsData) return [];
    return headerTestsData.firstPeriod?.total_authors?.slice(0, 5).map((item, index) => {
      return {
        name: item.author,
        testsNumber: item.amount,
        growthPercentage: headerTestsData.growth.total_authors[index].percent,
      };
    });
  }, [headerTestsData]);

  const preparedTopTests = useMemo(() => {
    if (!headerTestsData) return [];
    return headerTestsData.firstPeriod?.top_ab_tests.map((item) => {
      return {
        title: item.title,
        views: item.views,
      };
    });
  }, [headerTestsData]);

  return (
    <div className={`mail-report-container ${pageLoaded ? pageLoaded : ""}`}>
      <div className="mail-report-header">
        <div className="mail-report-header-left">
          <h1> Smartify </h1>
          <h2>
            <span>{type}</span> feedback
          </h2>
          <p className="date">
            {type === "daily" && <>{yesterdayDate}</>}
            {type === "weekly" && <WeekOfTheYear />}
          </p>
        </div>
        <div className="mail-report-header-right">
          <div className="logo"></div>
          <p className="for">for</p>
          <div className="brand-logo">
            <img src={currentBrand?.domainSettings?.domainlogo} alt="" />
          </div>
        </div>
      </div>
      <ReportRowWithNumbers
        type={type}
        title={"notifications"}
        description={`These are the resume values that illustrate your final performance over ${
          type === "weekly" ? "the past week" : "yesterday"
        }.`}
        items={[
          {
            title: (
              <p>
                notifications <b>send</b>
              </p>
            ),
            mainNumber: {
              value: firstPeriod?.send || 0,
              percentage: false,
            },
            growth: growth?.send,
          },
          {
            title: (
              <p>
                notifications <b>done</b>
              </p>
            ),
            mainNumber: {
              value: firstPeriod?.done || 0,
              percentage: false,
            },
            growth: growth?.done,
          },
          {
            title: (
              <p>
                action <b>percentage</b>
              </p>
            ),
            mainNumber: {
              value: firstPeriod?.actions_part_percent || 0,
              percentage: true,
            },
            growth: growth?.done,
          },
        ]}
      />
      <ReportRowWithNumbers
        type={type}
        title={"actions"}
        description={`These are the resume values that illustrate your final performance over ${
          type === "weekly" ? "the past week" : "yesterday"
        }.`}
        items={[
          {
            title: (
              <p>
                <b>own platform</b> action
              </p>
            ),
            mainNumber: {
              value: firstPeriod?.own_platform_actions_percent || 0,
              percentage: true,
            },
            growth: growth?.own_platform_actions_percent,
          },
          {
            title: (
              <p>
                <b>social</b> action
              </p>
            ),
            mainNumber: {
              value: firstPeriod?.social_actions_percent || 0,
              percentage: true,
            },
            growth: growth?.social_actions_percent,
          },
          {
            title: (
              <p>
                <b>rest</b> action
              </p>
            ),
            mainNumber: {
              value: firstPeriod?.rest_actions_percent || 0,
              percentage: true,
            },
            growth: growth?.rest_actions_percent,
          },
        ]}
      />
      <ReportRowWithList
        mainTitle="notifications"
        title="notifications not done"
        items={preparedNotificationNotDoneItems}
        indicators="arrows"
        type={type}
      />
      <ReportRowWithList
        mainTitle="missed opportunities"
        title="missed opportunities"
        items={preparedMissedOpportunitiesItems}
        indicators="dots"
        type={type}
      />
      <ReportRowWithNumbers
        type={type}
        title={"roi"}
        description={"These are the resume values that illustrate your final performance over the past week."}
        items={[
          {
            title: <p>ROI: own platform clicks</p>,
            mainNumber: {
              value:
                roiOwnPlatformData?.totalCurrentPeriod === 0
                  ? 0
                  : (roiOwnPlatformData?.totalCurrentPeriod / 1000).toFixed(0) + "K" || 0,
              percentage: false,
            },
            growth: roiOwnPlatformData?.diff?.toFixed(2),
          },
          {
            title: <p>ROI: social clicks</p>,
            mainNumber: {
              value:
                roiSocialData?.totalCurrentPeriod === 0
                  ? 0
                  : roiSocialData?.totalCurrentPeriod < 1000
                  ? roiSocialData?.totalCurrentPeriod
                  : (roiSocialData?.totalCurrentPeriod / 1000).toFixed(0) + "K" || 0,
              percentage: false,
            },
            growth: roiSocialData?.diff?.toFixed(2) || 0,
          },
          {
            title: <p>ROI: conversions</p>,
            mainNumber: {
              value:
                roiConversionsData?.totalCurrentPeriod === 0
                  ? 0
                  : (roiConversionsData?.totalCurrentPeriod / 1000).toFixed(0) + "K" || 0,
              percentage: false,
            },
            growth: roiConversionsData?.diff?.toFixed(2) || 0,
          },
        ]}
      />
      <ReportRowABTests topAuthors={preparedTopAuthors} topTests={preparedTopTests} />
      <ReportRowWithNumbers
        type={type}
        title={"AB tests"}
        description={"These are the resume values that illustrate your final performance over the past week."}
        items={[
          {
            title: <p>number of AB tests</p>,
            mainNumber: {
              value: headerTestsData?.firstPeriod?.total || 0,
              percentage: false,
            },
            growth: headerTestsData?.growth?.total,
          },
          {
            title: <p>percentage of winners</p>,
            mainNumber: {
              value: headerTestsData?.firstPeriod?.winners_percent || 0,
              percentage: true,
            },
            growth: headerTestsData?.growth?.winners_percent,
          },
          {
            title: <p>percentage of non-A</p>,
            mainNumber: {
              value: headerTestsData?.firstPeriod?.nonAWinner_percent || 0,
              percentage: true,
            },
            growth: headerTestsData?.growth?.nonAWinner_percent,
          },
        ]}
      />
      <div className="mail-report-footer">
        <div className="logo"></div>
        <div className="footer-info-container">
          <div>
            <p>Smartocto Netherlands</p>
            <p>Flavus 1</p>
            <p>6541LJ Nijmegen</p>
          </div>
          <div>
            <p>Smartocto Serbia</p>
            <p>Slobodana Selenica 44</p>
            <p>21203 Veternik, Novi Sad</p>
          </div>
          <div>
            <p className="hidden">.</p>
            <p className="hidden">.</p>
            <p>info@smartocto.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmartifyReport;
