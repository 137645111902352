import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "./store";
import { HashRouter, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";
import useIsFeatureAllowed from "./customHooks/useIsFeatureAllowed";
import useCheckUserPermissions from "./customHooks/useCheckUserPermissions";
// Admin pages
import Admin from "./pages/Admin/Admin";
import Users from "./pages/Admin/UsersPage/Users";
import Notifiers from "./pages/Admin/NotifiersPage/Notifiers";
import Imports from "./pages/Admin/ImportsPage/Imports";
import Brands from "./pages/Admin/BrandsPage/Brands";
import CreditUsage from "./pages/Admin/CreditUsage";
import UmbrellaBrands from "./pages/Admin/UmbrellaBrands";
import MailReports from "./pages/Admin/MailReports/MailReports";
import ReportSmartifyDaily from "./pages/Admin/MailReports/ReportSmartifyDaily";
import ReportSmartifyWeekly from "./pages/Admin/MailReports/ReportSmartifyWeekly";
// Client pages
import Stream from "./pages/Feed/Stream";
import Stories from "./pages/Feed/Stories";
import Videos from "./pages/Feed/Videos";
import Bundles from "./pages/Feed/Bundles";
import Inked from "./pages/Feed/Inked";
import Settings from "./pages/Settings";
import Pages from "./pages/Feed/Pages";
import Current from "./pages/Current";
import NoLongerAvailable from "./pages/NoLongerAvailable";
// Logbook items
import Logbook from "./pages/Logbook/Logbook";
import ImpactRadar from "./pages/Logbook/ImpactRadar/ImpactRadar";
import ABTests from "./pages/Logbook/ABTests/ABTests";
import CurrentVideo from "./pages/Logbook/CurrentVideo";
import Multibrand from "./pages/Logbook/Multibrand/Multibrand";
import ReadingTime from "./pages/Logbook/ReadingTime";
import AICredits from "./pages/Logbook/AICredits";
import RPORecommender from "./pages/Logbook/RPORecommender";
import { fetchCurrentBrand } from "./store/slices/currentBrand";
import { fetchCurrentUser } from "./store/slices/currentUser";
import { fetchSegments } from "./store/slices/activeFeedItem";
// General Loading Page
import LoadingPage from "./pages/LoadingPage";

import Api from "./store/api/Api";
import { loginViaBrandSwith } from "./store/slices/auth";
import NewUser from "./pages/NewUser";
import GoogleAnalyticsListener from "./components/googleAnalytics/GoogleAnalyticsListener";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SmartoctoAI from "./pages/SmartoctoAI";

type BrandState = {
  currentBrand: {
    isLoading: boolean;
    error: Object | null;
    data: {
      features: Array<string>;
      domain: string;
    } | null;
  };
};

const AuthRoutes = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch();
  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);
  const brandLoading = useSelector((state: BrandState) => state.currentBrand.isLoading);
  const userLoading = useSelector((state: any) => state.currentUser.isLoading);

  useEffect(() => {
    if (!brandInfo?.domain) {
      dispatch(fetchCurrentBrand());
      dispatch(fetchCurrentUser());
      dispatch(fetchSegments());
    }
  }, []);

  if (brandLoading || userLoading) return <LoadingPage />;

  return <Routes>{children}</Routes>;
};

const UnAuthRoutes = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);
  const user = useSelector((state: authState) => state.auth.token);

  let authValue = null as null | string;
  const hash = window.location.hash;
  const queryString = hash.split("?")[1];

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    authValue = urlParams.get("auth");
  }

  if (!authValue) {
    return (
      <Routes>
        <Route path="/new_user" element={<NewUser />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  } else {
    if (!brandInfo?.domain) {
      dispatch(fetchCurrentBrand(authValue, navigate));
      dispatch(fetchCurrentUser());
      dispatch(fetchSegments());
    }
  }

  if (!user) {
    <LoadingPage />;
  }
  return <Routes>{children}</Routes>;
};

type authState = {
  auth: {
    loading: boolean;
    token: string;
    error: object | null;
  };
};

export default function Router() {
  const user = useSelector((state: authState) => state.auth.token);
  const isFeatureAllowed = useIsFeatureAllowed();
  const checkUserPermissions = useCheckUserPermissions();
  const dispatch = useAppDispatch();

  const checkBrand = async () => {
    const api = new Api();
    const result = await api.getCurrentBrand();
    if (result.loggedin) {
      dispatch(loginViaBrandSwith());
    }
  };

  if (!user) {
    checkBrand();
  }

  return (
    <PersistGate persistor={persistor}>
      <HashRouter>
        <GoogleAnalyticsListener />
        {user ? (
          <AuthRoutes>
            {/* CLIENT PAGES */}
            <Route path="/" element={<Stream />} />
            <Route path="/stream/:id" element={<Stream />} />
            <Route path="/stream" element={<Stream />} />
            {isFeatureAllowed("stories") && (
              <>
                <Route path="/stories" element={<Stories />} />
                <Route path="/stories/:id" element={<Stories />} />
              </>
            )}
            {isFeatureAllowed("videos") && (
              <>
                <Route path="/videos" element={<Videos />} />
                <Route path="/videos/:id" element={<Videos />} />
              </>
            )}
            {isFeatureAllowed("landingPages") && (
              <>
                <Route path="/pages" element={<Pages />} />
                <Route path="/pages/:id" element={<Pages />} />
              </>
            )}
            {isFeatureAllowed("bundles") && (
              <>
                <Route path="/bundles" element={<Bundles />} />
                <Route path="/bundles/:id" element={<Bundles />} />
              </>
            )}
            <Route path="/inked" element={<Inked />} />
            <Route path="/inked/:id" element={<Inked />} />
            <Route path="/reports/current/ob" element={<Current />} />
            <Route path="/reports/current" element={<NoLongerAvailable />} />
            <Route path="/settings" element={<Settings />} />
            {isFeatureAllowed("ai") && <Route path="/smartoctoai" element={<SmartoctoAI />} />}
            {/* ADMIN PAGES */}
            {checkUserPermissions("admin") && (
              <>
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/users/:id" element={<Users />} />
                <Route path="/admin/multibrand" element={<Multibrand />} />
                <Route path="/admin/notifiers" element={<Notifiers />} />
                <Route path="/admin/notifiers/:id" element={<Notifiers />} />
                <Route path="/admin/imports" element={<Imports />} />
                <Route path="/admin/imports/:id" element={<Imports />} />
                <Route path="/admin/brands" element={<Brands />} />
                <Route path="/admin/brands/:id" element={<Brands />} />
                <Route path="/admin/brands/:id/:subModalId" element={<Brands />} />
                <Route path="/admin/creditUsage" element={<CreditUsage />} />
                <Route path="/admin/umbrellaBrands" element={<UmbrellaBrands />} />
                <Route path="/mailReports/" element={<MailReports />} />
              </>
            )}
            <Route path="/mailReports/smartifyDaily" element={<ReportSmartifyDaily />} />
            <Route path="/mailReports/smartifyWeekly" element={<ReportSmartifyWeekly />} />
            {checkUserPermissions("superuser") && (
              <>
                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/users/:id" element={<Users />} />
                <Route path="/admin/multibrand" element={<Multibrand />} />
              </>
            )}
            {/* LOGBOOK PAGES */}
            <Route path="/logbook" element={<Logbook />} />
            <Route path="/logbook/impact-radar" element={<ImpactRadar />} />
            {isFeatureAllowed("tentacles") && <Route path="/reports/tentacles" element={<ABTests />} />}
            {isFeatureAllowed("tentacles") && <Route path="/logbook/abtests" element={<ABTests />} />}
            <Route path="/reports/multiBrand" element={<NoLongerAvailable />} />
            <Route path="/reports/multiBrand/:id" element={<NoLongerAvailable />} />
            {isFeatureAllowed("videos") && <Route path="/reports/currentVideo/ob" element={<CurrentVideo />} />}
            {isFeatureAllowed("readingtime") && <Route path="/reports/current/readingtime" element={<ReadingTime />} />}
            {isFeatureAllowed("recommender") && <Route path="/recommender" element={<RPORecommender />} />}
            {isFeatureAllowed("ai") && <Route path="/reports/credits" element={<AICredits />} />}

            <Route path="*" element={<Navigate to="/stream" />} />
          </AuthRoutes>
        ) : (
          <UnAuthRoutes>
            {/* Can be accessed by auth query param */}
            <Route path="/reports/current/ob" element={<Current />} />
            <Route path="/reports/currentVideo/ob" element={<CurrentVideo />} />
            <Route path="/reports/current/readingtime" element={<ReadingTime />} />
            {/* ------------ */}
            <Route path="/new_user" element={<NewUser />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </UnAuthRoutes>
        )}
      </HashRouter>
    </PersistGate>
  );
}
