import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<any>;
  isLoading: boolean;
  error: Object | null;

  notifierTypes: Array<Object>;
  notifierTypesLoading: boolean;
  notifierTypesError: Object | null;

  updatingNotifier: boolean;
  updatingNotifierError: Object | null;

  creatingNotifier: boolean;

  deletingNotifier: boolean;
  deletingNotifierError: Object | null;

  loadingMailReports: boolean;
  mailReportResponse: string | null;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,

  notifierTypes: [],
  notifierTypesLoading: true,
  notifierTypesError: null,

  updatingNotifier: false,
  updatingNotifierError: null,

  creatingNotifier: false,

  deletingNotifier: false,
  deletingNotifierError: null,

  loadingMailReports: false,
  mailReportResponse: null
};

const notifiers = createSlice({
  name: "notifiers",
  initialState: INITIAL_STATE,
  reducers: {
    fetchNotifiersStart: (state: State) => {
      state.isLoading = true;
    },
    fetchNotifiersSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchNotifiersFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchNotifierTypesStart: (state: State) => {
      state.notifierTypesLoading = true;
    },
    fetchNotifierTypesSuccess: (state: State, action) => {
      state.notifierTypesLoading = false;
      state.notifierTypes = action.payload;
    },
    fetchNotifierTypesFailed: (state: State, action) => {
      state.notifierTypesLoading = false;
      state.notifierTypesError = action.payload;
    },

    updateNotifierStart: (state: State) => {
      state.updatingNotifier = true;
    },
    updateNotifierSuccess: (state: State, action) => {
      const updatedNotifier = action.payload
      state.data = state.data.map((notifierItem) => {
        // Check if the brand is the one being updated
        if (notifierItem.hasOwnProperty('id') && notifierItem.id === updatedNotifier.id) {
          return updatedNotifier;
        } else {
          return notifierItem;
        }
      });
      state.updatingNotifier = false;
    },
    updateNotifierFailed: (state: State, action) => {
      state.updatingNotifier = false;
      state.updatingNotifierError = action.payload;
    },

    createNotifierStart: (state: State) => {
      state.creatingNotifier = true;
    },
    createNotifierSuccess: (state: State, action) => {
      state.creatingNotifier = false;
      state.data = action.payload;
    },
    createNotifierFailed: (state: State, action) => {
      state.creatingNotifier = false;
    },

    deleteNotifierStart: (state: State) => {
      state.deletingNotifier = true;
    },
    deleteNotifierSuccess: (state: State, action) => {
      state.deletingNotifier = false;
      state.data = action.payload;
    },
    deleteNotifierFailed: (state: State, action) => {
      state.deletingNotifier = false;
    },

    switchMailReportsStart: (state: State) => {
      state.loadingMailReports = true;
      state.mailReportResponse = null;
    },
    switchMailReportsSuccess: (state: State, action) => {
      state.loadingMailReports = false;

      if (action.payload.state === 'active') {
        if (!action.payload.response) {
          state.mailReportResponse = `No reports have been changed`;
        } else {
          state.mailReportResponse = `Following reports are now active: ${action.payload.response}`;
        }
      } else {
        if (!action.payload.response) {
          state.mailReportResponse = `No reports have been changed`;
        } else {
          state.mailReportResponse = `Following reports have been switched off: ${action.payload.response}`;
        }
      }
    },
    switchMailReportsFailed: (state: State, action) => {
      state.loadingMailReports = false;
    },


    sendMailReportsStart: (state: State) => {
      state.loadingMailReports = true;
      state.mailReportResponse = null;
    },
    sendMailReportsSuccess: (state: State, action) => {
      state.loadingMailReports = false;

      if (!action.payload) {
        state.mailReportResponse = 'Something went wrong... check logs';
      } else {
        if ((action.payload instanceof Array) && action.payload.length > 0) {
          const reportsSend = action.payload.map(report => report.name).join(',');
          state.mailReportResponse = `The following reports will be send one by one for each minute, starting in 2 minutes: ${reportsSend}`;
        }
      }
    },
    sendMailReportsFailed: (state: State, action) => {
      state.loadingMailReports = false;
    },

    deleteTempListenersStart: (state: State) => {
      state.loadingMailReports = true;
      state.mailReportResponse = null;
    },
    deleteTempListenersSuccess: (state: State, action) => {
      state.loadingMailReports = false;

      if ((action.payload instanceof Array)) {
        state.mailReportResponse = `The following listeners (notifiers) have been deleted: ${action.payload}`;
      } else {
        state.mailReportResponse = action.payload;
      }
    },
    deleteTempListenersFailed: (state: State, action) => {
      state.loadingMailReports = false;
    },

    resetImportUpdateState: (state: State) => {
      state.updatingNotifier = false;
      state.updatingNotifierError = null;
    },
  },
});

export default notifiers.reducer;

const {
  fetchNotifiersStart,
  fetchNotifiersSuccess,
  fetchNotifiersFailed,
  fetchNotifierTypesStart,
  fetchNotifierTypesSuccess,
  fetchNotifierTypesFailed,
  updateNotifierStart,
  updateNotifierSuccess,
  updateNotifierFailed,
  createNotifierStart,
  createNotifierSuccess,
  createNotifierFailed,
  deleteNotifierStart,
  deleteNotifierSuccess,
  deleteNotifierFailed,
  switchMailReportsStart,
  switchMailReportsSuccess,
  switchMailReportsFailed,
  sendMailReportsStart,
  sendMailReportsSuccess,
  sendMailReportsFailed,
  deleteTempListenersStart,
  deleteTempListenersSuccess,
  deleteTempListenersFailed,
} = notifiers.actions;

export const fetchNotifiers = () => async (dispatch: Function) => {
  try {
    dispatch(fetchNotifiersStart());
    const api = new Api();
    const result = await api.getNotifiers();
    dispatch(fetchNotifiersSuccess(result));
  } catch (err) {
    dispatch(fetchNotifiersFailed(err));
  }
};

export const fetchNotifierTypes = () => async (dispatch: Function) => {
  try {
    dispatch(fetchNotifierTypesStart());
    const api = new Api();
    const result = await api.getNotifierTypes();
    dispatch(fetchNotifierTypesSuccess(result));
  } catch (err) {
    dispatch(fetchNotifierTypesFailed(err));
  }
};

export const updateNotifier = (notifierId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(updateNotifierStart());
    const api = new Api();
    const result = await api.postUpdateNotifier(notifierId, payload);
    dispatch(updateNotifierSuccess(result));
  } catch (err) {
    dispatch(updateNotifierFailed(err));
  }
};

export const createNotifier = (brandId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(createNotifierStart());
    const api = new Api();
    const result = await api.postCreateNotifier(brandId, payload);
    dispatch(createNotifierSuccess(result));
  } catch (err) {
    dispatch(createNotifierFailed(err));
  }
};

export const deleteNotifier = (notifierId: string) => async (dispatch: Function) => {
  try {
    dispatch(deleteNotifierStart());
    const api = new Api();
    const result = await api.deleteNotifier(notifierId);
    dispatch(deleteNotifierSuccess(result));
  } catch (err) {
    dispatch(deleteNotifierFailed(err));
  }
};

export const switchMailReports = (state: string) => async (dispatch: Function) => {
  try {
    dispatch(switchMailReportsStart());
    const api = new Api();
    const result = await api.getSwitchMailReports(state);
    dispatch(switchMailReportsSuccess({ state: state, response: result }));
  } catch (err) {
    dispatch(switchMailReportsFailed(err));
  }
};

export const sendMailReports = () => async (dispatch: Function) => {
  try {
    dispatch(sendMailReportsStart());
    const api = new Api();
    const result = await api.getSendMailReports();
    dispatch(sendMailReportsSuccess(result));
  } catch (err) {
    dispatch(sendMailReportsFailed(err));
  }
};


export const deleteTempListeners = () => async (dispatch: Function) => {
  try {
    dispatch(deleteTempListenersStart());
    const api = new Api();
    const result = await api.getDeleteTempListeners();
    dispatch(deleteTempListenersSuccess(result));
  } catch (err) {
    dispatch(deleteTempListenersFailed(err));
  }
};