import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import MainNavigation from "../../components/navigation/MainNavigation";
import SideNavigation from "../../components/navigation/SideNavigation";
import Story from "../../components/story/Story.jsx";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchFeedStreamItems } from "../../store/slices/feed";
import {
  fetchActiveFeedItem,
  fetchActiveFeedItemCVI,
  fetchActiveFeedItemSegmentCVI,
  fetchActiveFeedItemNumbers,
} from "../../store/slices/activeFeedItem";
import { CircleLoader } from "../../icons/Loaders";
import DataNotAvailable from "../../components/errors/DataNotAvailable";
import { v4 as uuidv4 } from "uuid";

const Stream = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { streamData: items, isLoading } = useSelector((state: any) => state.feed);
  const selectedSegment = useSelector((state: any) => state.activeFeedItem.selectedSegment);
  const { dashboardSettings } = useSelector((state: any) => state.currentBrand.data);
  const [selectedFilter, setSelectedFilter]: [string, Function] = useState("");
  const [selectedSections, setSelectedSections]: [string[], Function] = useState([]);
  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const filter = searchParams.get("filter");
  const rawSections = searchParams.get("sectionFilters")?.split(",") || [];
  const formattedSections = rawSections.map((section) => {
    return "/" + section.replace(" > ", "/");
  });

  // Initial fetching of stream data
  useEffect(() => {
    setSelectedFilter(filter || "");
    setSelectedSections(formattedSections);
    dispatch(fetchFeedStreamItems(formattedSections));
  }, [dispatch]);

  // Interval refetching of stream data
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchFeedStreamItems(formattedSections));
    }, dashboardSettings?.storyListRefreshTime || 60000);

    return () => clearInterval(interval);
  }, [dispatch, dashboardSettings?.storyListRefreshTime, formattedSections]);

  const transformedItems = useMemo(() => {
    return items.map((e: any) => {
      return {
        title: e.title,
        description: e.content,
        itemType: e.item_type,
        itemData: e.item_data,
        postId: e.post_id,
        itemId: e.item_id,
        postType: e.post_type,
        inked: e.inked,
        pubDate: e.pubdate,
        key: uuidv4(),
      };
    });
  }, [items]);

  useEffect(() => {
    if (!id && transformedItems[0]?.postId) {
      navigate(`/stream/${transformedItems[0].postId}`);
    }
  }, [transformedItems, id, navigate]);

  useEffect(() => {
    if (!id) return;
    if (id) {
      dispatch(fetchActiveFeedItem(id, {}));
      if (selectedSegment.key !== "" && selectedSegment.key !== "No segments selected") {
        dispatch(fetchActiveFeedItemSegmentCVI(id, selectedSegment.key));
      } else {
        dispatch(fetchActiveFeedItemCVI(id));
      }
      dispatch(fetchActiveFeedItemNumbers(id));
    }
  }, [id]);

  const filters = [
    {
      filter: "Acts",
      selected: false,
    },
    {
      filter: "Updates",
      selected: false,
    },
    {
      filter: "Comments",
      selected: false,
    },
    {
      filter: "Tentacles",
      selected: false,
    },
  ];

  const pageContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loader-container">
          <CircleLoader />
        </div>
      );
    }
    if (!isLoading && transformedItems.length === 0) {
      return <DataNotAvailable pageType="stream" />;
    }
    return <Story />;
  }, [isLoading, transformedItems.length]);

  return (
    <div>
      <MainNavigation />
      <div className="container">
        <SideNavigation
          title="Stream"
          items={transformedItems}
          filters={filters}
          isLoading={isLoading}
          fetchFunctionForSections={fetchFeedStreamItems}
          hasSectionFilter
          filtersMessage={"Which stream items do you want to see?"}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
        />
        <div className="main-page-container">{pageContent}</div>
      </div>
    </div>
  );
};

export default Stream;
