import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../components/ui/table/Table";
import MainNavigation from "../../../components/navigation/MainNavigation";
import Button from "../../../components/ui/common/Button";
import { EditIcon, DeleteIcon } from "../../../icons/Icons";
import Modal from "../../../components/ui/modal/Modal";
import EditDisplayedColumnsModal from "../EditDisplayedColumnsModal";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import { deleteNotifier, fetchNotifierTypes, fetchNotifiers, updateNotifier } from "../../../store/slices/notifiers";
import { CircleLoader } from "../../../icons/Loaders";
import { PlusIcon } from "../../../icons/Icons";
import EditNotifiersModal from "../../../components/modals/EditNotifiersModal/EditNotifiersModal";
import ToggleSwitch from "../../../components/ui/toggleSwitch/ToggleSwitch";
import NotifiersActions from "../../../components/notifiers/NotifiersActions";
import cronstrue from "cronstrue";
import cronParser from "cron-parser";
import { v4 as uuidv4 } from "uuid";
import "../Admin.scss";
import "./Notifiers.scss";

const Notifiers = () => {
  const dispatch = useAppDispatch();
  const notifiersNavPath = "/admin/notifiers";
  const [editingColumns, setEditingColumns] = useState(false);
  const [editingNotifier, setEditingNotifier] = useState(false);
  const [notifierEditData, setNotifierEditData] = useState({});
  const [tableKey, setTableKey] = useState(1);

  const notifiers = useSelector((state: any) => state.notifiers.data);
  const isLoading = useSelector((state: any) => state.notifiers.isLoading);

  useEffect(() => {
    document.title = "Admin - Notifiers - Smartocto real-time";
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id && notifiers.length > 0) {
      handleEdit(id);
    }
  }, [id, notifiers]);

  useEffect(() => {
    if (!id && Object.keys(notifierEditData).length > 0) {
      navigate(`${notifiersNavPath}/${notifierEditData["id"]}`);
    }
  }, [id, navigate, notifierEditData]);

  useEffect(() => {
    if (!id && Object.keys(notifierEditData).length === 0) {
      navigate(notifiersNavPath);
    }
  }, [id, navigate, notifierEditData]);

  const columns = [
    { title: "id", label: "id" },
    { title: "name", label: "name" },
    { title: "type", label: "type" },
    { title: "cronTime", label: "Cron time" },
    { title: "active", label: "Active" },
    { title: "params", label: "params" },
    { title: "actions", label: "actions" },
  ];

  useEffect(() => {
    dispatch(fetchNotifiers());
    dispatch(fetchNotifierTypes());
  }, []);

  const handleEdit = (id) => {
    const notifierData = notifiers.filter((item) => item.id === id)[0];
    if (notifierData) {
      setEditingNotifier(true);
      setNotifierEditData(notifierData);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteNotifier(id));
    }
  };

  const handleToggleNotifier = (notifier) => {
    const payload = { ...notifier, active: !notifier.active };
    dispatch(updateNotifier(notifier.id, payload));
  };

  function objectToArray(obj) {
    return Object.keys(obj).map((key) => ({
      title: key,
      value: obj[key].toString(),
    }));
  }

  const preparedColumns = useMemo(() => {
    try {
      const tablesConfig = JSON.parse(localStorage.tablesConfig);
      const excludedColumns = tablesConfig.notifiers;
      return columns.filter((obj) => !excludedColumns.includes(obj.label));
    } catch {
      return columns;
    }
  }, []);

  const dataWithActions = notifiers.map((e, i) => {
    return {
      ...e,
      active: (
        <ToggleSwitch
          checked={e.active}
          onChange={() => {
            handleToggleNotifier(e);
          }}
        />
      ),
      cronTime: (
        <div>
          {cronstrue.toString(e.cronTime)}
          <br />
          <b> Next iteration </b>
          <br />
          {cronParser.parseExpression(e.cronTime).next().toString()}
        </div>
      ),
      params: (
        <ul>
          {objectToArray(e.params || {}).map((e, i) => {
            return (
              <li key={i}>
                <b>{e.title}</b> : {e.value.split(",").join(", ")}
              </li>
            );
          })}
        </ul>
      ),
      name: <small>{e.name}</small>,
      search: [e.id, e.name, e.type, e?.params?.message, e?.params?.messageSpecial].join(" "),
      sort: { id: e.id, name: e.name, type: e.type, active: e.active },
      type: <small>{e.type}</small>,
      actions: (
        <div style={{ display: "flex" }}>
          <Button
            variant="warning"
            data-testid={`editButton${i}`}
            size="small"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              handleEdit(e.id);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            variant="danger"
            data-testid={`deleteButton${i}`}
            size="small"
            onClick={() => {
              handleDelete(e.id);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    };
  });

  const handleModalClose = () => {
    navigate(notifiersNavPath);
    setEditingNotifier(false);
    setNotifierEditData({});
  };

  return (
    <>
      <Modal
        isOpen={editingColumns}
        onClose={() => {
          setEditingColumns(false);
        }}
      >
        <EditDisplayedColumnsModal tableTitle="notifiers" columns={columns} />
      </Modal>

      <Modal isOpen={editingNotifier} onClose={handleModalClose}>
        <EditNotifiersModal data={notifierEditData} closeModal={handleModalClose} />
      </Modal>

      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Notifiers</h1>
        <div className="admin-page-header-btns notifiers-header-buttons">
          <div className="column">
            <Button
              style={{ marginLeft: 20 }}
              title="Edit Notifiers columns"
              variant="success"
              onClick={() => {
                setEditingColumns(true);
              }}
            >
              <EditIcon /> Edit Columns
            </Button>
          </div>
          <div className="column">
            <NotifiersActions />
          </div>
        </div>
        {!isLoading ? (
          <div className="table-container">
            <Table key={tableKey} showRowNumbers={true} rowData={dataWithActions} columnsData={preparedColumns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Notifiers;
